const state = {
    idEmpresa: null,
    estadosEmpresaState: null,
    cidadeEstadosEmpresaState: null
}

const mutations = {
    SET_ID_EMPRESA(state, data){
        state.idEmpresa = data
    },

    SET_ESTADOS_ENDERECO_EMPRESA(state, data) {
        state.estadosEmpresaState = data;
    },

    SET_CIDADE_ESTADOS_ENDERECO_EMPRESA(state, data) {
        state.cidadeEstadosEmpresaState = data;
    }
}

const actions = {
  
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
